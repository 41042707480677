import service from "./request";

// 新增站点接口
const addSiteAPI = (data) => {
  return service({
    url: `/manage/custom/station/add`,
    method: "post",
    data,
  });
};

// 获取站点接口数据
const siteDataRenderAPI = (data) => {
  return service({
    url: `/manage/custom/station/page`,
    method: "post",
    data,
  });
};

// 编辑更新站点接口数据
const updateSiteAPI = (data) => {
  return service({
    url: `/manage/custom/station/update`,
    method: "put",
    data,
  });
};

// 删除站点接口数据
const deleteSiteAPI = (data) => {
  return service({
    url: `/manage/custom/station/delete`,
    method: "delete",
    data,
  });
};

// 导出站点接口数据
const exportSiteAPI = (data) => {
  return service({
    url: `/manage/custom/station/export`,
    method: "post",
    data,
    responseType: "blob",
  });
};

// 车辆管理-查询村通客运的线路接口
const selectLineAPI = (data) => {
  return service({
    url: `/manage/village/line/listSelectLine`,
    method: "post",
    data,
  });
};

export {
  addSiteAPI,
  siteDataRenderAPI,
  updateSiteAPI,
  deleteSiteAPI,
  exportSiteAPI,
  selectLineAPI,
};

/* ----------------- 班线定制 ---------------------- */
//定制班线-站点列表
const stationList = (data) => {
  return service({
    url: `/manage/custom/station/list`,
    method: "post",
    data,
  });
};
//定制班线-线路分页
const stationLineList = (data) => {
  return service({
    url: `/manage/custom/line/page`,
    method: "post",
    data,
  });
};
//定制班线-编辑线路
const stationLineUpDate = (data) => {
  return service({
    url: `/manage/custom/line/update`,
    method: "put",
    data,
  });
};
//定制班线-线路同步
const stationLineSycn = (data) => {
  return service({
    url: `/manage/custom/line/sycn`,
    method: "post",
    data,
  });
};
//定制班线-导出线路
const stationLineExport = (data) => {
  return service({
    url: `/manage/custom/line/export`,
    method: "post",
    data,
    responseType: "blob",
  });
};
//定制班线-删除线路
const stationLineDelete = (data) => {
  return service({
    url: `/manage/custom/line/delete`,
    method: "delete",
    data,
  });
};
//定制班线-获取线路规划
const lineTrailInfo = (data) => {
  return service({
    url: `/manage/custom/line/trail/info`,
    method: "get",
    data,
  });
};
//定制班线-线路站点列表
const lineStationList = (data) => {
  return service({
    url: `/manage/custom/line/station/list`,
    method: "post",
    data,
  });
};
//定制班线-批量编辑线路站点
const lineStationUpdate = (data) => {
  return service({
    url: `/manage/custom/line/station/update/batch`,
    method: "put",
    data,
  });
};
//编辑线路规划
const lineTrailEdit = (data) => {
  return service({
    url: `/manage/custom/line/trail/edit`,
    method: "post",
    data,
  });
};

export {
  stationList, //定制班线-站点列表
  stationLineList, //定制班线-线路分页
  stationLineUpDate, //定制班线-编辑线路
  stationLineSycn, //定制班线-线路同步
  stationLineExport, //定制班线-导出线路
  stationLineDelete, //定制班线-删除线路
  lineTrailInfo, //定制班线-获取线路规划
  lineStationList, //定制班线-线路站点列表
  lineStationUpdate, //定制班线-批量编辑线路站点
  lineTrailEdit, //编辑线路规划
};

/* ----------------- 班线定制 ---------------------- */

/* ----------------- 村通客运 ---------------------- */
const allArea = (data) => {
  return service({
    url: `/manage/village/district/area/all`,
    method: "get",
    data,
  });
};
//分页查询线路
const queryLinePage = (data) => {
  return service({
    url: `/manage/village/line/page`,
    method: "post",
    data,
  });
};
//新增线路
const villageLineAdd = (data) => {
  return service({
    url: `/manage/village/line`,
    method: "post",
    data,
  });
};
//更新线路
const villageLineUpDate = (data) => {
  return service({
    url: `/manage/village/line`,
    method: "put",
    data,
  });
};
//村通客线路详情
const villageLineInfo = (data) => {
  return service({
    url: `/manage/village/line?id=${data}`,
    method: "get",
  });
};
//删除线路
const villageLineDelete = (data) => {
  return service({
    url: `/manage/village/line`,
    method: "delete",
    data,
  });
};
//路径规划详情
const villageLinePlan = (data) => {
  return service({
    url: `/manage/village/route/planning`,
    method: "get",
    data,
  });
};
//村通-导出线路
const villageLineExport = (data) => {
  return service({
    url: `/manage/village/line/export`,
    method: "post",
    data,
    responseType: "blob",
  });
};
//路径规划
const villageRoutePlan = (data) => {
  return service({
    url: `/manage/village/route/planning`,
    method: "post",
    data,
  });
};
//村通客运停靠站点分页查询
const queryVillageSitePage = (data) => {
  return service({
    url: `/manage/villagesite/queryVillageSitePage`,
    method: "post",
    data,
  });
};
//更新村通客运停靠站点
const updateVillageSite = (data) => {
  return service({
    url: `/manage/villagesite/updateVillageSite`,
    method: "put",
    data,
  });
};
//保存村通客运停靠站点
const saveVillageSite = (data) => {
  return service({
    url: `/manage/villagesite/saveVillageSite`,
    method: "post",
    data,
  });
};
//删除村通客运停靠站点
const deleteVillageSiteById = (data) => {
  return service({
    url: `/manage/villagesite/deleteVillageSiteById`,
    method: "delete",
    data,
  });
};

export {
  allArea, //查询所有区县
  queryLinePage, //分页查询线路
  villageLineAdd, //新增线路
  villageLineUpDate, //更新线路
  villageLineDelete, //删除线路
  villageLinePlan, //路径规划详情
  villageLineExport, //村通-导出线路
  villageRoutePlan, //路径规划
  saveVillageSite,
  updateVillageSite,
  queryVillageSitePage,
  deleteVillageSiteById,
  villageLineInfo,
};

/* ----------------- 村通客运 ---------------------- */
