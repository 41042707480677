<template>
  <div class="addMiddleSites">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="sites">
      <h3 style="padding-left: 20px">停靠点</h3>
      <div class="site-box">
        <div
          :key="index"
          v-for="(tag, index) in dynamicTags"
          draggable
          class="item"
          @dragstart="handleDragStart($event, tag)"
          @dragover.prevent="handleDragOver($event, tag)"
          @dragenter="handleDragEnter($event, tag)"
          @dragend="handleDragEnd($event, tag)"
        >
          <el-tag
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            <i class="iconfont its_menu"></i>
            {{ tag.siteName }}
          </el-tag>
        </div>
        <el-button class="button-new-tag" size="small" @click="showInput"
          >+
        </el-button>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      title="添加停靠点"
      width="1100px"
      @close="cancelFun"
    >
      <div class="addSites">
        <el-checkbox
          v-model="tag.checked"
          v-for="(tag, index) in sites"
          :key="index"
          :disabled="tag.disabled"
          @change="(e) => selectSite(e, index)"
          >{{ tag.siteName }}</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelFun">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmFun"
          >确 认</el-button
        >
      </span></el-dialog
    >
  </div>
</template>

<script>
import {
  villageLineUpDate,
  villageLineInfo,
  queryVillageSitePage,
} from "@/api/lib/customizedBus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      dynamicTags: [],
      sites: [],
      drag1: null,
      drag2: null,
      info: {},
    };
  },
  mounted() {
    this.renderData();
  },
  methods: {
    // 返回列表
    returnList() {
      this.$router.push("/projectTravel/villagePassLineManage");
    },
    handleDragStart(e, items) {
      this.drag1 = items; //开始拖动时，暂时保存当前拖动的数据。
    },
    handleDragEnd(e, items) {
      if (this.drag2 == this.drag1) return;
      var newItems = [...this.dynamicTags]; //拷贝一份数据进行交换操作。
      var src = newItems.indexOf(this.drag1); //获取数组下标
      var dst = newItems.indexOf(this.drag2);
      newItems.splice(dst, 1, this.drag1);
      newItems.splice(src, 1, this.drag2);
      this.dynamicTags = newItems;
      this.drag1 = null; //拖动结束后，清除数据
      this.drag2 = null;
      const arr = this.dynamicTags.filter((e) => e.checked).map((i) => i.id);
      const data = { ...this.info, siteList: arr };
      villageLineUpDate(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.renderData();
        }
      });
    },
    handleDragOver(e) {
      e.dataTransfer.dropEffect = "move"; //在dragenter中针对放置目标来设置!
    },
    handleDragEnter(e, items) {
      this.drag2 = items;
      e.dataTransfer.effectAllowed = "move"; //为需要移动的元素设置dragstart事件
    },
    handleClose(tag) {
      if (!this.info.status && this.dynamicTags.length <= 2) {
        this.$message.warning("请禁用线路后再删除停靠点");
        return;
      }
      this.$confirm("确认将该停靠点从当前线路删除？", "提示", {
        type: "warning",
      }).then(() => {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        const arr = this.dynamicTags.map((i) => i.id);
        const data = { ...this.info, siteList: arr };
        villageLineUpDate(data).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("删除成功");
            this.renderData();
          }
        });
      });
    },
    showInput() {
      this.dialogVisible = true;
    },
    confirmFun() {
      const arr = this.sites.filter((e) => e.checked).map((i) => i.id);
      const data = { ...this.info, siteList: arr };
      villageLineUpDate(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.dialogVisible = false;
          this.renderData();
        }
      });
    },
    cancelFun() {
      this.dialogVisible = false;
    },
    selectSite(e, index) {
      if (e) {
        const arr = this.sites.filter((e) => e.checked);
        if (arr.length > 50) {
          this.sites[index].checked = false;
          this.$message.warning("最多只能添加50个停靠点");
        }
      }
    },
    renderData() {
      const id = this.$route.query.id;
      villageLineInfo(id).then((res) => {
        if (res.code == "SUCCESS") {
          const arr = res.data.siteList;
          this.info = res.data;
          queryVillageSitePage({ currentPage: 1, pageSize: 9999 }).then(
            (res) => {
              if (res.code == "SUCCESS") {
                this.sites = res.data.list
                  .filter((e) => !e.status)
                  .map((e) => {
                    e.checked = false;
                    e.disabled = false;
                    if (arr.find((i) => e.id == i)) {
                      e.disabled = true;
                      e.checked = true;
                    }
                    return e;
                  });
                this.dynamicTags = [];
                arr.forEach((e) => {
                  const obj = this.sites.find((i) => e == i.id);
                  if (obj) this.dynamicTags.push(obj);
                });
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-table .disabled-row {
  color: #d5d5d5;
}
</style>

<style scoped lang="scss">
.addMiddleSites {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 32px);
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .sites {
    width: 1000px;
    .site-box {
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 20px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
    .button-new-tag {
      margin-left: 20px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .its_menu {
      font-size: 13px;
    }
  }
  .addSites {
    width: 100%;
    max-height: 550px;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
}
</style>
