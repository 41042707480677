var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addMiddleSites" },
    [
      _c("div", { staticClass: "return", on: { click: _vm.returnList } }, [
        _c("i", { staticClass: "iconfont its_ba_fanhui" }),
      ]),
      _c("div", { staticClass: "sites" }, [
        _c("h3", { staticStyle: { "padding-left": "20px" } }, [
          _vm._v("停靠点"),
        ]),
        _c(
          "div",
          { staticClass: "site-box" },
          [
            _vm._l(_vm.dynamicTags, function (tag, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  attrs: { draggable: "" },
                  on: {
                    dragstart: function ($event) {
                      return _vm.handleDragStart($event, tag)
                    },
                    dragover: function ($event) {
                      $event.preventDefault()
                      return _vm.handleDragOver($event, tag)
                    },
                    dragenter: function ($event) {
                      return _vm.handleDragEnter($event, tag)
                    },
                    dragend: function ($event) {
                      return _vm.handleDragEnd($event, tag)
                    },
                  },
                },
                [
                  _c(
                    "el-tag",
                    {
                      attrs: { closable: "", "disable-transitions": false },
                      on: {
                        close: function ($event) {
                          return _vm.handleClose(tag)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont its_menu" }),
                      _vm._v(" " + _vm._s(tag.siteName) + " "),
                    ]
                  ),
                ],
                1
              )
            }),
            _c(
              "el-button",
              {
                staticClass: "button-new-tag",
                attrs: { size: "small" },
                on: { click: _vm.showInput },
              },
              [_vm._v("+ ")]
            ),
          ],
          2
        ),
      ]),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                title: "添加停靠点",
                width: "1100px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.cancelFun,
              },
            },
            [
              _c(
                "div",
                { staticClass: "addSites" },
                _vm._l(_vm.sites, function (tag, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: index,
                      attrs: { disabled: tag.disabled },
                      on: { change: (e) => _vm.selectSite(e, index) },
                      model: {
                        value: tag.checked,
                        callback: function ($$v) {
                          _vm.$set(tag, "checked", $$v)
                        },
                        expression: "tag.checked",
                      },
                    },
                    [_vm._v(_vm._s(tag.siteName))]
                  )
                }),
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancelFun } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.confirmFun },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }